import { AppBar, Toolbar, Typography, IconButton, Box, Button, useScrollTrigger } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useRef } from "react";

const pages = ['About',  'Inside', 'Contact', 'Reservation']; //'Notice',

// const clickButton = useRef();

const handleDrawerToggle = () => {

}
const handleCloseNavMenu = (e) => {
    
    //console.log(e)
    console.log(e.target.id)
    console.log(e.target.name)
    //console.log(e.currentTarget)
    const _id = e.target.id;
    let posY = ((inId) => {
        let result = 0;
        switch (inId) {
            case 'About': result = 730; break;
            case 'Notice': result = 1100; break;
            case 'Inside': result = 1330; break;
            case 'Contact': result = 2700; break;
            case 'Reservation': result = 0; break;
        }

        return result;
    })(_id);

    window.scrollTo({ top: posY, behavior: 'smooth' });

}



const Header = () => {
    return(
        <>
            <AppBar positions="fixed" style={{'background': '#cec3ba'}}>
                <Toolbar>
                    <IconButton
                        size="medium"
                        color="white"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2 , display: { xs: 'flex', md: 'none' }}}
                    >
                        <MenuIcon color="white" />
                    </IconButton>
                    <Typography style={{'color': '#333'}} fontWeight= "bold">
                        TOURSBEGIN
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: 'flex-end' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                id={page}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: '#333', display: 'block' }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default Header;