import './App.css';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';

function App() {
  return (
    <div className="App" style={{'background-color': '#836d5f'}}>
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default App;
