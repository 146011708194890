
import { Box, Typography, Link } from "@mui/material";

function Copyright() {
    return (
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        {/* <Link color="inherit" href="https://www.toursbegin.com/"> */}
        <Link color="inherit" href="https://naver.me/GQYK5ajw">
            www.toursbegin.com
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const Footer = () => {
    return(
        <>
            
            <Box sx={{ bgcolor: '#553628', p: 6 }} component="footer">
                <Typography variant="h6" align="center" gutterBottom>
                투어스비긴 | TORUSBEGIN
                </Typography>
                <Typography
                variant="subtitle1"
                align="center"
                color="text.secondary"
                component="p"
                >
                서울시 서대문구 증가로 18 연희빌딩 3층 302호
                </Typography>
                <Copyright />
            </Box>
        </>
        
    )
}

export default Footer;