import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';

export default () => {
    return (
        <Swiper
            modules={[Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            autoplay={{ delay: 5000 }}	// 추가
        >
            <SwiperSlide><img src='./images/main_L_img_01.png'></img></SwiperSlide>
            <SwiperSlide><img src='./images/main_L_img_02.png'></img></SwiperSlide>
            <SwiperSlide><img src='./images/main_L_img_03.png'></img></SwiperSlide>
            <SwiperSlide><img src='./images/main_L_img_04.png'></img></SwiperSlide>
            <SwiperSlide><img src='./images/main_L_img_05.png'></img></SwiperSlide>
            <SwiperSlide><img src='./images/main_L_img_06.png'></img></SwiperSlide>

        </Swiper>

    )
}