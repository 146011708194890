import { Container, Grid, Card, CardMedia, CardContent,Link, CardActions, Typography, Button, Box, Stack, CardActionArea, ImageList, ImageListItem, ImageListItemBar, IconButton } from "@mui/material";
import CompSwiper from "./CompSwiper";
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';



const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const cardTitles = ['DOOR - outside','DOOR - inside','SNACKBAR','WALL - outside','CAFETERIA','CENTER SEAT','REST AREA','VENDING MACHINE','CORNER SEAT'];
const cardDesc = ['저희 스터디 카페 입구입니다. 우측에 키오스크를 통해서 입장권을 구매하시고 입장하실 수 있어요.',
                    '입구에 들어오시면 우측 선반에 휴지와 사탕, 의견함등이 있으며 벽에는 화장실 키가 있습니다.',
                    '입구 좌측 벽면에는 간단한 스넥이 준비되어 있어요.',
                    '카페 밖 벽면에는 액자가 붙어있어요.',
                    '스터디 카페 안쪽에는 카페테리아가 있습니다. 식수, 티, 커피 등이 마련되어 있으니 언제든지 편하게 이용해 주세요.',
                    '카페 중앙에는 넓은 책상자리가 마련되어 있어요.',
                    '카페 뒤쪽 중앙에는 편안한 소파와 책장에 여러 가지 책들이 비치되어 있어요. 잠시 휴식 시간을 가져보세요.',
                    '안쪽 벽면에는 자판기가 있어요. 여러 가지 음료가 있는데 그중에서 밀크티는 정말 최고예요.',
                    '넓은 중장 자리 외에도 각 벽면에 집중하기 좋은 개성 있는 자리들이 있어요.'];

const itemData = [
    {
      img: './images/__1.jpg',
      title: 'Bed',
      author: 'swabdesign',
    },
    {
      img: './images/__2.jpg',
      title: 'Books',
      author: 'Pavel Nekoranec',
    },
    {
      img: './images/__3.jpg',
      title: 'Sink',
      author: 'Charles Deluvio',
    },
    {
      img: './images/__4.jpg',
      title: 'Kitchen',
      author: 'Christian Mackie',
    },
    {
      img: './images/__5.jpg',
      title: 'Blinds',
      author: 'Darren Richardson',
    },
    {
      img: './images/__6.jpg',
      title: 'Chairs',
      author: 'Taylor Simpson',
    },
    {
      img: './images/__7.jpg',
      title: 'Laptop',
      author: 'Ben Kolde',
    },
    {
      img: './images/__8.jpg',
      title: 'Doors',
      author: 'Philipp Berndt',
    },
    {
      img: './images/__9.jpg',
      title: 'Coffee',
      author: 'Jen P.',
    },
    {
      img: './images/__1.jpg',
      title: 'Storage',
      author: 'Douglas Sheppard',
    },
    {
      img: './images/__2.jpg',
      title: 'Candle',
      author: 'Fi Bell',
    },
    {
      img: './images/__3.jpg',
      title: 'Coffee table',
      author: 'Hutomo Abrianto',
    },
  ];

const Main = () => {
    return(
        <>
            <Box
                sx={{
                    pt: 12,
                    mb: 15
                }}
            >
                <CompSwiper />
            </Box>
            
            {/* <Box
                sx={{
                    bgcolor: 'background.paper',
                    mb: 15,
                    width: "100%"
                }}
            >
                <img src="./images/__1.jpg"></img>
            </Box> */}
            {/* <Box sx={{ mt:20, width: 1000, height: 450, overflowY: 'scroll' }}>
                <ImageList variant="masonry" cols={3} gap={8}>
                    {itemData.map((item) => (
                    <ImageListItem key={item.img}>
                        <img
                        src={`${item.img}?w=248&fit=crop&auto=format`}
                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.title}
                        loading="lazy"
                        />
                        <ImageListItemBar position="below" title={item.author} />
                    </ImageListItem>
                    ))}
                </ImageList>    
            </Box> */}
            {/* <Box sx={{
                    bgcolor: 'background.paper',
                    t: 12,
                    mb: 15,
                    ml: 'auto',
                    mr: 'auto',
                    display: 'flex',
                    maxWidth: '1280px',
                    flexDirection: 'column',
                    alignSelf: 'center'

                }}
            >
                <img src="./images/main_img_01.png"></img>
            </Box> */}
            <Box
                sx={{
                    //bgcolor: '#cec3ba',
                    bgcolor: '#8c7d70',
                    pt: 5,
                    pb: 6,
                    mb: 10
                }}
            >
                <Container maxWidth="sm">
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        //fontWeight= "bold"
                        gutterBottom
                        fontSize={'3rem'}
                    >
                        스터디카페 & 워크플레이스
                    </Typography>
                    <Typography variant="h8" align="center" color="text.secondary" gutterBottom>
                        낯설면서도 정겨움, 새침한 듯하지만 매너 있고, 조용하면서도 힙함,
                    </Typography>
                    <Typography variant="h8" align="center" color="text.secondary" paragraph>
                        이 모든 다양성이 공존하는 자유로운 연희동에서.
                    </Typography>
                    <Typography variant="h8" align="center" color="text.secondary" paragraph>
                        이런 연희동에는 어떤 스터디카페가 어울릴까요?
                    </Typography>
                    <Typography variant="h8" align="center" color="text.secondary" gutterBottom>
                        TOURSBEGIN은 바로 이 고민에서 시작했습니다.
                    </Typography>
                    <Typography variant="h8" align="center" color="text.secondary" paragraph>
                        그리고 다음 질문을 던져 봅니다.
                    </Typography>
                    <Typography variant="h8" align="center" color="text.secondary" gutterBottom>
                        어느 공간에 들어섰을 때, 그곳이 나를 포근히 품어 줄 수 있다면
                    </Typography>
                    <Typography variant="h8" align="center" color="text.secondary" paragraph>
                        어떤 느낌일까?
                    </Typography>
                    <Typography variant="h8" align="center" color="text.secondary" gutterBottom>
                        TOURSBEGIN은 깊은 고민 끝에 이렇게 대답하고 싶습니다.
                    </Typography>
                    <Typography variant="h8" align="center" color="text.secondary" gutterBottom>
                        내 방과 같은 포근한 분위기, 일상의 활력을 불어넣어 줄 음악과,
                    </Typography>
                    <Typography variant="h8" align="center" color="text.secondary" paragraph>
                        공부나 작업의 피로를 덜어 줄 수 있는 조명과 인테리어들....
                    </Typography>
                    <Typography variant="h8" align="center" color="text.secondary" gutterBottom>
                        탁 트인 창과 다양한 책, 그리고 아름다운 작품들 사이에서
                    </Typography>
                    <Typography variant="h8" align="center" color="text.secondary" paragraph>
                        당신의 생각을 넓혀보세요.
                    </Typography>
                    {/* <Stack
                        sx={{ pt: 4 }}
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        >
                        <Button variant="contained">Main call to action</Button>
                        <Button variant="outlined">Secondary action</Button>
                        </Stack> */}
                </Container>
            </Box>
            
            <Container sx={{ py: 8 }} maxWidth="md">
            <Box
                sx={{
                    mb: 5
                }}
            >
                <Typography variant="h6" align="center" gutterBottom>
                    INSIDE - photo callery
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="text.secondary"
                    component="p"
                >
                    영업시간 : 오후 12시 ~ 오후 11시 ( 월, 금 휴무 )
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="text.secondary"
                    component="p"
                >
                    대표연락처 : 02-338-2888
                </Typography>
                
            </Box>
                <Grid container spacing={4}>
                    {cards.map((card, idx) => (
                        <Grid item key={card} xs={12} sm={6} md={4}>
                            <Card
                                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                            >
                                <CardMedia
                                    component="img"
                                    height="180"
                                    // sx={{
                                    // // 16:9
                                    // pt: '56.25%',
                                    // }}
                                    image={`./images/__${card}.jpg`}
                                    alt="random"
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                    {cardTitles[idx]}
                                    </Typography>
                                    <Typography>
                                    {cardDesc[idx]}
                                    </Typography>
                                </CardContent>
                                {/* <CardActions>
                                    <Button size="small">View</Button>
                                    <Button size="small">Edit</Button>
                                </CardActions> */}
                                {/* <CardActionArea>
                                    <CardMedia
                                    component="img"
                                    height="180"
                                    // sx={{
                                    //     // 16:9
                                    //     pt: '56.25%',
                                    //     }}
                                    image={`./images/__${card}.jpg`}
                                    alt="green iguana"
                                    />
                                    <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Lizard
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Lizards are a widespread group of squamate reptiles, with over 6,000
                                        species, ranging across all continents except Antarctica
                                    </Typography>
                                    </CardContent>
                                </CardActionArea> */}
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            
            {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}> */}
            

            <Box
                sx={{
                    pt: 12,
                    mb: 5
                }}
            >
                <Typography variant="h6" align="center" gutterBottom>
                    CONTACT
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="text.secondary"
                    component="p"
                >
                    영업시간 : 오후 12시 ~ 오후 11시 ( 월, 금 휴무 )
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="text.secondary"
                    component="p"
                >
                    대표연락처 : 02-338-2888
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="text.secondary"
                    component="p"
                >
                    차량주차 : 건물주차 가능
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="text.secondary"
                    component="p"
                >
                    지번주소 : 서울시 서대문구 연희동 92-18 연희빌딩 3층 302호
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="text.secondary"
                    component="p"
                >
                    도로명주소 : 서울시 서대문구 증가로 18 연희빌딩 3층 302호
                </Typography>
                <Link color="inherit" href="https://instagram.com/toursbegin?igshid=YmMyMTA2M2Y=" about="blank">
                    <InstagramIcon />
                </Link>
                <Link color="inherit" href="https://www.youtube.com/@toursbegin" about="blank">
                    <YouTubeIcon />
                </Link>
                
            </Box>
            <Box 
                sx={{ mb:10, display: { xs: 'none', md: 'flex' } }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                // minHeight="100vh"
            >
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.3669443699437!2d126.92897561560896!3d37.56997617979717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9922b8b16f35%3A0xf9da5a0a6562c31c!2z7Yis7Ja07Iqk67mE6ri0KOyKpO2EsOuUlOy5tO2OmCk!5e0!3m2!1sko!2skr!4v1673686365017!5m2!1sko!2skr" width="90%" height="600" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </Box>
            <Box sx={{ mb:10, display: { xs: 'flex', md: 'none' } }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                //minHeight="100vh"
            >
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.3669443699437!2d126.92897561560896!3d37.56997617979717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9922b8b16f35%3A0xf9da5a0a6562c31c!2z7Yis7Ja07Iqk67mE6ri0KOyKpO2EsOuUlOy5tO2OmCk!5e0!3m2!1sko!2skr!4v1673686365017!5m2!1sko!2skr" width="90%" height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </Box>
        </>
    )
}

export default Main;